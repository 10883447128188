import { useMemo } from "react";
import { PropTypes } from "prop-types";

import { useTracking } from "../../context/Tracking";
import { useAnnouncementBarPopup } from "../../context/AnnouncementBarProvider";
import { useSubscriptionPopup } from "../../context/SubscriptionPopupProvider";

import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useCurrentFormatCurrency, useCurrentCountryCode } from "../../hooks/usePrices";
import { useShippingInfo, useShippingMessage } from "../../hooks/useShippingInfo";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import { Link } from "../al_components/ALLink";
import { ALVideo } from "../ALComponents";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";
import IconArrowSm from "../icons/svgs/arrow-circle-sm.svg";

import * as collectionCardsStyles from "./collectionCardsStyles.module.scss";

function CollectionCard({ card, index, shouldOrderProducts, collectionHandle }) {
  const { setPopupOpen } = useAnnouncementBarPopup();
  const { setSubscriptionPopupOpen } = useSubscriptionPopup();

  const { trackPLPCardClick } = useTracking();
  const formatCurrency = useCurrentFormatCurrency();
  const dict = useLocalizedSentenceDict();
  const shippingInfo = useShippingInfo();
  const discountsInfo = useDiscountsInfo();
  const countryCode = useCurrentCountryCode();
  const shippingMessage = useShippingMessage(countryCode);

  // const [customCardTitle, updateCustomCardTitle] = useState(null);

  const cardLinkExists = card?.link?.title !== "empty" && card?.link?.title !== "null";
  const subtitleExists = card?.subtitle !== "empty" && card?.subtitle !== "null";

  let cardTitle = card?.title && card?.title !== "null" ? card.title : "";

  if (cardTitle.includes(" - ")) {
    cardTitle = cardTitle.split(" - ")[1];
  }

  const customCardTitle = useMemo(() => {
    if (cardTitle.includes("shipping") && shippingInfo) {
      return shippingMessage;
    }
    if (cardTitle.includes("MASTER") && countryCode && discountsInfo) {
      const freeGiftDiscount = discountsInfo.find(({ type }) => type === DISCOUNT_TYPES.FREE_GIFT);
      return dict.get(cardTitle, formatCurrency(freeGiftDiscount?.freegift?.minAmount));
    }
  }, [cardTitle, countryCode, dict, discountsInfo, formatCurrency, shippingInfo, shippingMessage]);

  let urlImage = useResponsiveWithHydrationFix(
    card?.images[0]?.mobileImage?.file?.url,
    card?.images[0]?.desktopImage?.file?.url
  );

  if (!card?.images || card?.images?.length === 0 || card?.images[0]?.altText === "empty") {
    urlImage = null;
  }

  const hasVideo =
    (!!card.videoId && card.videoId !== "null") ||
    (!!card.video?.url && card.video?.url !== "null");

  const quantityPercentDiscount = discountsInfo.find(
    ({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT
  );
  const orderClassName = useResponsiveWithHydrationFix(
    "",
    !!quantityPercentDiscount && quantityPercentDiscount?.code === card?.discountCode
      ? collectionCardsStyles.orderZero
      : ""
  );

  const imageThumbnailClassName = useResponsiveWithHydrationFix(
    collectionCardsStyles.video_container_none,
    ""
  );

  let linkContent = null;

  if (card.link.link.link.includes("luxe_details=true")) {
    linkContent = (
      <button
        type="button"
        className={`${collectionCardsStyles.card_collection_link} subtext subtext--bold`}
        style={{ color: card.textColor }}
        onClick={(e) => {
          e.preventDefault();
          setSubscriptionPopupOpen(true);
        }}
      >
        {card.link.title}
        <IconArrowSm width="21" stroke={card.textColor} />
      </button>
    );
  } else if (card.link.link.link.includes("offer_details=true")) {
    linkContent = (
      <button
        type="button"
        className={`${collectionCardsStyles.card_collection_link} subtext subtext--bold`}
        style={{ color: card.textColor }}
        onClick={(e) => {
          e.preventDefault();
          setPopupOpen(true);
        }}
      >
        <span className="subtext">
          {dict.get("Code automatically applied at checkout. Exclusions apply.")}
          <span className="subtext" style={{ textDecoration: "underline" }}>
            {dict.get("Read more")}.
          </span>
        </span>
      </button>
    );
  } else if (card.link.link.link.includes("http")) {
    linkContent = (
      <a
        className={`${collectionCardsStyles.card_collection_link} subtext subtext--bold`}
        href={card.link.link.link}
      >
        {card.link.title}
        <IconArrowSm width="21" stroke={card.textColor} />
      </a>
    );
  } else {
    linkContent = (
      <Link
        className={`${collectionCardsStyles.card_collection_link} subtext subtext--bold`}
        aria-label={card.link.title}
        to={card.link.link.link}
        style={{ color: card.textColor }}
        onClick={() => {
          trackPLPCardClick({
            collectionHandle,
            link: card.link.link.link,
            title: card.link.title,
          });
        }}
      >
        {card.link.title}
        <IconArrowSm width="21" stroke={card.textColor} />
      </Link>
    );
  }

  return (
    <div
      className={`
        ${
          hasVideo
            ? collectionCardsStyles.card_collection_video
            : collectionCardsStyles.card_collection
        }
        ${
          shouldOrderProducts
            ? `order-${index + 1} ${collectionHandle}-order-${
                index + 1
              } collection_card_${collectionHandle}_${index + 1}`
            : ""
        }
        ${orderClassName}
      `}
      key={`card_collection_${index}`}
    >
      <div className={`${collectionCardsStyles.card_collection_content}`}>
        {hasVideo ? (
          <>
            <ALVideo
              videoId={card.videoId}
              videoUrl={card.video?.url}
              customClasses={{
                container: `${collectionCardsStyles.card_video_container}`,
                vimeo_container: `${collectionCardsStyles.card_video_container_vimeo}`,
                image_thumbnail: imageThumbnailClassName,
                skeleton_container: "pdp_sk_m",
                skeleton_image: "pdp_sk_image",
                video_container: `${collectionCardsStyles.video_container_none}`,
                video: `${collectionCardsStyles.card_video_container_vimeo_video}`,
              }}
            />
            {cardLinkExists && (
              <Link
                aria-label={card.link.title}
                to={card.link.link.link}
                className={`${collectionCardsStyles.card_video_link_container} ${
                  cardLinkExists ? collectionCardsStyles.has_links : ""
                }`}
              >
                <span
                  className={`${collectionCardsStyles.card_video_link} subtext subtext--bold`}
                  style={{ color: card.textColor }}
                >
                  {card.link.title}
                  <IconArrowSm width="21" stroke={card.textColor} />
                </span>
              </Link>
            )}
          </>
        ) : (
          <div
            className={`${collectionCardsStyles.card_collection_container} ${
              cardLinkExists ? collectionCardsStyles.has_links : ""
            }`}
            style={
              urlImage
                ? { backgroundImage: `url(${urlImage})` }
                : { backgroundColor: card.backgroundColor }
            }
          >
            <div>
              <h3 className="mt-0 mb-10 h2 h2--uc h2--bold" style={{ color: card.textColor }}>
                {customCardTitle ||
                  (cardTitle.includes("<br>")
                    ? cardTitle.split("<br>").map((it, i) => (
                        <span key={`card_ds_${i}`}>
                          {it} <br />
                        </span>
                      ))
                    : cardTitle)}
              </h3>
              {subtitleExists && (
                <p className="text" style={{ color: card.textColor }}>
                  {card?.subtitle}
                </p>
              )}
            </div>
            <div className={`${collectionCardsStyles.card_collection_content_link}`}>
              {cardLinkExists && linkContent}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

CollectionCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  shouldOrderProducts: PropTypes.bool,
  collectionHandle: PropTypes.string,
};

export default withALErrorBoundary({
  name: "CollectionCard",
  priority: "P3",
})(CollectionCard);
